import { LitElement, html, css } from 'lit';
import { krumStyles } from '../styles/krum-styles.js';
import { flexStyles } from '../styles/flex-styles.js';
import '../components/krum-card-image.js';

export class KrumSectionTools extends LitElement {
    static get properties() {
        return {
            cards: {
                type: Array,
            },
            type: {
                type: String,
                attribute: true,
                reflect: true,
            },
        };
    }
    constructor() {
        super();
        this.cards = [];
    }
    static get styles() {
        return [
            flexStyles,
            krumStyles,
            css`
              :host {
                display: block;
                overflow-x: hidden;
                background-color: var(--krum-background-color);
                font-family: var(--krum-font-family);
                max-width: var(--krum-content-max-width);
                margin-left: auto;
                margin-right: auto;
              }

              :host([type="secondary"]) {
                background-color: var(--krum-secondary-background-color);
              }

              :host([type="action"]) {
                background-color: var(--krum-secondary-text-color);
              }

              :host([type="action"]) .section-title {
                color: var(--krum-white-text-color);
              }

              :host([type="action"]) .section-text {
                  color: var(--krum-muted-white-text-color);
              }

              h1 {
                margin-bottom: 4px;
              }

              h3 {
                color: var(--krum-color-medium-dark-gray);
                margin: 0px;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 30px;
              }

              hr {
                border: 0px;
                border-top: 1px solid var(--krum-color-medium-gray);
              }



              .section {
                padding: 32px;
                padding-bottom: 120px;
                padding-top: 40px;
              }

              .section > * {
                max-width: var(--krum-content-max-width);
              }

              .section-title {
                font-size: 36px;
                font-weight: bold;
                margin-bottom: 16px;
                color: var(--krum-secondary-text-color);
              }

              .section-text {
                font-size: 24px;
                font-weight: 300;
                line-height: 1.5;
                color: var(--krum-focused-action-color);
                text-align: justify;
              }

              .card-section {
                margin-top: 80px;
              }

              .cards {
                align-items: flex-start;
                align-self: stretch;
                gap: 32px;
                margin-top: 40px;
              }

              /**
              * Mobile considerations for font sizes and section paddings at the
              * 540px breakpoint.  This will also adjust padding for the tile image
              * and the size of the overlay title.
              */
              @media all and (max-width: 540px) {
                .section {
                  padding: 64px 32px;
                }

                .section-title {
                  font-size: 24px;
                }

                .section-text {
                  font-size: 16px;
                }

              }
            `,
        ];
    }
    render() {
        return html`
        <section class="section vertical layout">
            <div>
              <h1>Notable Technologies</h1>
              <span>We develop platforms and golden-paths to maximize developer and operator experience. Take advantage of our experience to short-cut decision making and start shipping. These are some of the tools and capabilities we use help teams develop at top speed.</span>
            </div>
            <div class="card-section" style="margin-top: 40px">
              <h3>
                Cloud Providers
              </h3>
              <hr style="">
              <div class="cards horizontal layout wrap">
                  <krum-card-image name="Amazon Web Services" imageSrc="/images/logos/1200px-AmazonWebservices_Logo 1.png"></krum-card-image>
                  <krum-card-image name="Microsoft Azure" imageSrc="/images/logos/Microsoft_Azure_Logo 1.png"></krum-card-image>
                  <krum-card-image style="width:310px" name="Google Cloud" imageSrc="/images/logos/Google_Cloud_logo 1.png"></krum-card-image>
                  <krum-card-image style="" name="Civo" imageSrc="/images/logos/civo-blue-2.svg"></krum-card-image>
              </div>
            </div>
            <div class="card-section">
              <h3>
                Languages
              </h3>
              <hr style="">
              <div class="cards horizontal layout wrap">
                  <krum-card-image style="width: 84px;" name="NodeJS" imageSrc="/images/logos/590px-Node.js_logo 1.png"></krum-card-image>
                  <krum-card-image name="Python" imageSrc="/images/logos/python-logo-generic 1.png"></krum-card-image>
                  <krum-card-image style="width: 110px;" name="Golang" imageSrc="/images/logos/golang-logo.png"></krum-card-image>
                  <krum-card-image style="width: 84px;" name="Rust" imageSrc="/images/logos/rust-logo.png"></krum-card-image>
              </div>
            </div>
            <div class="card-section">
              <h3>
                Infrastructure
              </h3>
              <hr style="">
              <div class="cards horizontal layout wrap">
                  <krum-card-image style="width: 185px;" name="Kubernetes" imageSrc="/images/logos/kubernetes-horizontal-all-blue-color 1.png"></krum-card-image>
                  <krum-card-image style="width: 84px;" name="Helm" imageSrc="/images/logos/helm-horizontal-color 1.png"></krum-card-image>
                  <krum-card-image style="width: 202px;" name="Hashicorp Terraform" imageSrc="/images/logos/terraform-logo.png"></krum-card-image>
                  <krum-card-image style="width: 209px;" name="Rancher by SUSE" imageSrc="/images/logos/Rancher Logo.svg"></krum-card-image>
                  <krum-card-image style="width: 219px;" name="NeuVector by SUSE" imageSrc="/images/logos/neuvector-logo.svg"></krum-card-image>
                  <krum-card-image style="width: 231px;" name="Longhorn" imageSrc="/images/logos/logo-horizontal-longhorn.svg"></krum-card-image>
                  <krum-card-image style="width: 104px;" name="RKE" imageSrc="/images/logos/logo-horizontal-rke.svg"></krum-card-image>
                  <krum-card-image style="width: 124px;" name="k3s" imageSrc="/images/logos/logo-horizontal-k3s.svg"></krum-card-image>
              </div>
            </div>
            <div class="card-section">
              <h3>
                Networking
              </h3>
              <hr style="">
              <div class="cards horizontal layout wrap">
                  <krum-card-image style="width: 100px;" name="Istio" imageSrc="/images/logos/istio-seeklogo.com 1.png"></krum-card-image>
                  <krum-card-image style="" name="Cilium" imageSrc="/images/logos/cilium-light.svg"></krum-card-image>
                  <krum-card-image style="width: 100px;" name="Nginx" imageSrc="/images/logos/Nginx_logo.svg"></krum-card-image>
              </div>
            </div>
            <div class="card-section">
              <h3>
                Monitoring and Observability
              </h3>
              <hr style="">
              <div class="cards horizontal layout wrap">
                  <krum-card-image style="width: 237px;" name="Prometheus" imageSrc="/images/logos/prometheus-horizontal-color 1.png"></krum-card-image>
                  <krum-card-image style="width: 184px;" name="Sentry" imageSrc="/images/logos/Sentry Logo.svg"></krum-card-image>
                  <krum-card-image style="width: 290px;" name="ElasticSearch" imageSrc="/images/partner/partner-elasticsearch.png"></krum-card-image>
              </div>
            </div>
            <div class="card-section">
              <h3>
                Web and Application Frameworks
              </h3>
              <hr style="">
              <div class="cards horizontal layout wrap">
                  <krum-card-image style="width: 136px;" name="Lit-HTML" imageSrc="/images/logos/lit-logo.svg"></krum-card-image>
                  <krum-card-image style="width: 191px;" name="React and React-Native" imageSrc="/images/logos/react-logo.png"></krum-card-image>
                  <krum-card-image style="width: 181px;" name="Vue.js" imageSrc="/images/logos/vue-logo.png"></krum-card-image>
                  <krum-card-image style="width: 200px;" name="Next.js" imageSrc="/images/logos/nextjs-logo.png"></krum-card-image>
                  <krum-card-image style="width: 200px;" name="Next.js" imageSrc="/images/logos/astro-logo.svg"></krum-card-image>
              </div>
            </div>
            <!-- <div class="card-section">
              <h3>
                E-commerce + Money Management
              </h3>
              <hr style="">
              <div class="cards horizontal layout wrap">
                  <krum-card-image name="Stripe" imageSrc="/images/logos/logos-stripe.png"></krum-card-image>
              </div>
            </div> -->
        </section>
    `;
    }
}

customElements.define('krum-section-tools', KrumSectionTools);
